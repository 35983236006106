define("discourse/plugins/doi-resolver/lib/discourse-markdown/doi-resolver", ["exports", "pretty-text/pretty-text"], function (_exports, _prettyText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  (0, _prettyText.registerOption)((siteSettings, opts) => {
    opts.features['doi-resolver'] = true;
  });
  function resolveDOI(text) {
    return text.replace(/([^\/])(10.\d{4,9}\/[-._;()\/:A-Z0-9]+)/gi, "$1<a href=\"https:\/\/doi.org/$2\">$2</a>");
  }
  function setup(helper) {
    helper.addPreProcessor(resolveDOI);
  }
});